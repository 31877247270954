<template>
  <div class = "info-view">
    <div class = 'info-wrap' v-if='Object.keys(this.data).length > 0'>
      <div class = 'info-wrap_left'>
        <div class = 'info-desc' v-html='this.data.text'></div>
        <ul class = 'info-links'>
          <li><a :href="'mailto:' + this.data.email" target="_blank">{{ this.data.email }}</a></li>
          <li><a :href="this.data.instagram" target="_blank">Instagram</a></li>
        </ul>
      </div>
      <div class = 'info-wrap_right' :style="{ backgroundImage: this.backgroundImage }"></div>
    </div>
  </div>
</template>

<script>

import api from '../utils/api'

export default {

  name: 'Info',
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    // on mount, populate state with data 
    this.getData()
  },
  computed: {
    backgroundImage: function() {
      // todo: make this responsive/use srcset
      return 'url(' + this.data.desktop_image.files.thumbs.lg + ')'
    }
  },
  methods: {
    getData: function() {
      const self = this
      api.getPage(self.$route.name).then((response) => {
        self.data = response.data
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style lang="scss">

@import "../styles/_helpers.scss";

.info-view {
  position: fixed;
  top: 0;
  margin-top: $nav-height;
  // this height is contingent on footer height being same as header height
  // --- may need to be updated
  height: calc(100% - (#{$nav-height} * 2));
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.info-wrap {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  height: 100%;
  @include bp-xs {
    // flex-flow: column;
    display: block;
  }
  &_left {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    @include bp-xs {
      // height: 66.6%;
      height: 50%;
      width: 100%;
    }
    @include bp-md {
      flex-flow: row;
    }
    @include bp-lg {
      flex-flow: row;
    }
    @include bp-xl {
      flex-flow: row;
    }
    @include bp-xxl {
      flex-flow: row;
    }
  }
  &_right {
    width: 50%;
    height: 100%;
    flex: 1;
    background-size: cover;
    background-position: center center;
    @include bp-xs {
      width: 100%;
      // height: 33.3%;
      height: 50%;
    }
  }
}

.info-desc {
  padding: $margin;
  @include type-16_body;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include bp-md {
    width: 50%;
    border-right: solid black 1px;
  }
  @include bp-lg {
    width: 50%;
    border-right: solid black 1px;
  }
  @include bp-xl {
    width: 50%;
    border-right: solid black 1px;
  }
  @include bp-xxl {
    width: 50%;
    border-right: solid black 1px;
  }
}

.info-links {
  width: 100%;
  @include bp-md {
    width: 50%;
  }
  @include bp-lg {
    width: 50%;
  }
  @include bp-xl {
    width: 50%;
  }
  @include bp-xxl {
    width: 50%;
  }
  & li {
    display: flex;
    border-top: solid black 1px;
    @include bp-xs {
      // border-top: unset;
      &:last-child {
        border-bottom: solid black 1px;
      }
    }
    @include bp-md {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    @include bp-lg {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    @include bp-xl {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    @include bp-xxl {
      border-top: unset;
      border-bottom: solid black 1px;
    }
    & a {
      @include type-30;
      padding: 2.2rem $margin 2.2rem $margin;
      display: flex;
      width: 100%;
      &:hover {
        @include italic;
        background: black;
        color: white;
      }
    }
  }
}


</style>
